<template>
  <v-app>
    <v-main>
      <v-img
        v-bind="heroBgProps"
        position="center bottom"
        max-height="660"
        cover
        content-class="flex align-center justify-center"
      >
        <v-sheet
          color="transparent"
          class="text-center"
        >
          <v-img
            src="~/assets/images/logo-white.svg"
            width="120"
            class="mb-16 mx-auto"
          />
          <u-text
            color="white"
            class="text-heading-h2 mb-5"
          >
            <slot name="title" />
          </u-text>

          <v-defaults-provider :defaults="{ VSheet: { width: '85%' } }">
            <template v-if="smAndDown">
              <slot name="widget" />
            </template>
            <template v-else>
              <slot name="actions" />
            </template>
          </v-defaults-provider>

          <trustpilot-widget
            class="mt-6 flex justify-center"
            schema-type="Organization"
            style-height="90px"
            style-width="150px"
            template-id="53aa8807dec7e10d38f59f32"
            theme="dark"
          />
        </v-sheet>
      </v-img>

      <v-container>
        <v-row class="lg:pt-15">
          <v-col
            cols="24"
            md="14"
            lg="15"
            xl="14"
            xxl="13"
            offset-xl="1"
            offset-xxl="2"
          >
            <slot />

            <template v-if="faq">
              <u-text
                class="font-galano text-28 xl:text-36 mb-4"
                text="FAQs"
              />

              <storyblok-component
                v-for="(blok, key) in faq"
                :key="key"
                v-model="faqModel"
                :blok="blok"
              />
            </template>
          </v-col>

          <v-col
            v-if="mdAndUp"
            cols="24"
            sm="10"
            md="9"
            lg="8"
            xl="7"
            xxl="6"
            offset-md="1"
            class="relative"
          >
            <div class="sticky top-2 lg:top-3">
              <slot name="widget" />
              <trustpilot-widget
                class="mt-8 flex justify-center"
                schema-type="Organization"
                style-height="90px"
                style-width="150px"
                template-id="53aa8807dec7e10d38f59f32"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>

      <template v-if="smAndDown">
        <v-sheet
          color="#007b81"
          class="pt-19 pb-24"
        >
          <v-container class="px-6">
            <v-row>
              <v-col
                cols="24"
                class="text-center"
              >
                <u-text class="text-heading-h4 mb-3">
                  <slot name="mobile-widget-title" />
                </u-text>
                <div class="mb-6">
                  <slot name="mobile-widget-content" />
                </div>

                <slot name="mobile-actions" />
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </template>

      <v-sheet color="#f7f7f7">
        <v-container class="py-3">
          <v-row>
            <v-col
              cols="24"
              lg="14"
              xl="12"
              offset-lg="2"
              offset-xl="3"
            >
              <u-text
                font-size="11"
                color="#757476"
              >
                Terms and conditions*
              </u-text>
              <u-text
                font-size="11"
                color="#757476"
              >
                <slot name="terms" />
              </u-text>
              <u-text
                font-size="11"
                color="#757476"
              >
                Although unbiased.co.uk undertakes significant effort to ensure the information provided on this website is correct, it is your responsibility to confirm its accuracy with the adviser. Any decisions you take after speaking with the adviser must be made by you on the basis of your own investigations. Use of this service is subject to our general
                <u-link to="/legals/terms-of-use">
                  terms and conditions
                </u-link>.
              </u-text>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-main>

    <footer-default :items="footer" />
  </v-app>
</template>

<script setup lang="ts">
import hero from '#campaigns/assets/images/offer-hero.jpg'
import hero2 from '#campaigns/assets/images/offer-hero@2x.jpg'
import hero3 from '#campaigns/assets/images/offer-hero@3x.jpg'
import {
  FooterDefault,
} from '#components'

defineProps<{
  faq?: Record<any, any>[]
}>()

const { mdAndUp, smAndDown } = useDisplay()
const { footer } = useFooter()
const { initGTM } = useGtm()

initGTM()

const faqModel = ref(0)
const heroBgProps = computed(() => {
  return {
    src: hero,
    srcset: `${hero2} 2x, ${hero3} 3x`,
    ...(smAndDown.value
      ? {
          minHeight: '100vh',
        }
      : {}),
    ...(mdAndUp.value
      ? {
          minHeight: 460,
        }
      : {}),
  }
})
</script>
